.cards {
    margin: auto;
    width: auto;
    height: auto;   
}

.card--back {
     background-image: url("../../assets/natal2.png");
     background-size: cover;
     background-position: center;
     -webkit-print-color-adjust: exact;
     print-color-adjust: exact;
}

.card-Img {
    margin: auto;
    margin-top: 0.2rem;
    //margin-bottom: 1rem;
    padding: 0;
    width: 9.5rem !important;
    height: 3rem !important;
}

// img {
//      height: 1rem !important;
// }

.img-footer {
    margin: auto;
    margin-bottom: 0;
    width: 3rem !important;
    height: 3rem !important;
}

.qrcode {
    margin: auto;
}

.btn-zap {
    margin: auto;
    margin-top: 2rem;
}

@media print {
    #printPageButton {
       display: none;
    }
}  

.card-text-p {
    margin: 0;
}
