@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.bg_formcad{
    background-image: url('../../assets/background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    min-height: 100vh;
}

.w50{
    width: 50%;
    float: left;
    padding-right: 15px;
}
.box{
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
}

@media screen and (min-width: 1280px) {
    .box{
        display: flex;
        width: 930px;
    }
}
.img-box{
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 20px 20px 20px 20px;
}
@media screen and (min-width: 1280px) {
    .img-box{
        margin-bottom: 0;
        width: 50%;
        display: flex;
        align-items: top;
        padding: 20px;
        border-radius: 0  0 0 0;       
    }    
}
.img-box img{
    width: 100%;
}
.form-box{
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(40px);
    padding: 1.875rem 2.25rem;
    width: 100%;
    border-radius: 0 0 0 0;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 930px) {
    .form-box{
        border-radius: 1.25rem;
    }
}

.form-box h2{
    font-size: 30px;
}
.form-box p{
    font-weight: bold;
    color: #3D3D3D;
}
.form-box p a{
    color: #FF008A;
    text-decoration: none;
}
.form-box form{
    margin: 1.25rem 0;
}
form .input-group{
    margin-bottom: 1rem;
}
form .input-group label{
    color: #3D3D3D;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}
form .input-group input{
    width: 100%;
    height: 47px;
    background-color: rgba(255, 255, 255, 0.32);
    border-radius: 20px;
    border: 2px solid transparent;
    padding: 15px;
    font-size: 15px;
    color: #616161;
    transition: all 0.4s ease;
}
form .input-group input:focus{
    border-color: #ff008869;
}
form .input-btn{
    width: 100%;
    height: 2.9375rem;
    background: #468af0; 
    border-radius: 1.25rem;
    // outline: none; 
    border: none; 
    margin-top: 1rem;
    color: white;
    font-size: 3rem;
    cursor: pointer;
}

div.divcodpai {
    display: none;
}

div.elbutton {
    // display: block;
    pointer-events:visible;
}
